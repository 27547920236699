export default {
  system: {
    title: "Gendome Energy Digital System",
    weeks: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    box1: {
      title: "New energy charge",
      numDataText1: "Mains elec.",
      numDataText2: "Total",
      numDataText3: "Wind energy ",
      numDataText4: "Solar energy",
      echart: {
        legend1: "Solar",
        legend2: "Wind",
        legend3: "Mains",
        seriesTitle: "New energy",
      },
    },
    box2: {
      title: "Sales statistics",
      echart: {
        seriesTitle: "Sales",
      },
    },
    box3: {
      title1: "Total activated devices ",
      title2: "Devices activated this month",
      title3: "Devices activated today",
      title4: "Total device alarms",
      title5: "Device alarms this month",
      title6: "Device alarms today",
      echartTitle1: "Monthly national charging list",
      echartTitle2: "Rate of on-line devices today",
      echartTitle3: "Rate of activated devices today",
    },
    box4: {
      title1: "User data",
      title2: "Product sales category statistics",
      dataTitle1: "New users today",
      dataTitle2: "Yesterday active users",
      dataTitle3: "Monthly active users",
      dataTitle4: "Total users",
    },
    box5: {
      title: "New energy charge (recent 7d)",
      echart: {
        legend1: "Solar energy",
        legend2: "Wind energy",
        legend3: "Mains electricity",
      },
    },
    box6: {
      title: "Alarms (recent 30d)",
      echart: {
        yAxisTitle: "Alarms",
        average: "average",
        bg: "block bg",
      },
    },
    box7: {
      headers: ["Alarm Content", "Alarm Device", "Alarm Time"],
    },
    notify: {
      title: "Alarm",
      equipment: "Equipment",
      time: "Time",
      alarmContent: "Alarm Content",
    },
  },
  equipment: {},
};
