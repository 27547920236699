export default {
  system: {
    title: "坤联数字能源平台",
    weeks: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    box1: {
      title: "新能源充电量",
      numDataText1: "市电充电量",
      numDataText2: "总充电量",
      numDataText3: "风能充电量",
      numDataText4: "光伏充电量",
      echart: {
        legend1: "光伏充电量",
        legend2: "风能充电量",
        legend3: "市电充电量",
        seriesTitle: "新能源充电量",
      },
    },
    box2: {
      title: "销售统计",
      echart: {
        seriesTitle: "销售数",
      },
    },
    box3: {
      title1: "设备累计激活总数",
      title2: "本月设备激活数",
      title3: "今日设备激活数",
      title4: "设备告警总数",
      title5: "本月设备告警数",
      title6: "今日设备告警数",
      echartTitle1: "月度国家充电量榜单",
      echartTitle2: "今日设备在线率",
      echartTitle3: "今日设备激活率",
    },
    box4: {
      title1: "用户数据",
      title2: "产品销售品类统计",
      dataTitle1: "今日新增",
      dataTitle2: "昨日日活",
      dataTitle3: "月活(近30天)",
      dataTitle4: "总注册用户",
    },
    box5: {
      title: "新能源充电量(近7日)",
      echart: {
        legend1: "光伏充电量",
        legend2: "风能充电量",
        legend3: "市电充电量",
      },
    },
    box6: {
      title: "告警(近30日)",
      echart: {
        yAxisTitle: "告警数",
        average: "平均值",
        bg: "占位背景",
      },
    },
    box7: {
      headers: ["告警内容", "告警设备", "告警时间"],
    },
    notify: {
      title: "告警",
      equipment: "设备",
      time: "时间",
      alarmContent: "告警内容",
    },
  },
  equipment: {},
};
