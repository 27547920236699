export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}
export default {
    computed: {
        getTimeZone() {
            return (time, cFormat) => {
                // "2022-07-08T10:19:35"
                if (time) {
                    // var timezone = 16; //目标时区时间，东八区
                    // var offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
                    // var nowDate = new Date(time).getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
                    // var targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
                    // return parseTime(targetDate)
                    if (typeof time == "number") {
                        let d = new Date(time);
                        return parseTime(d, cFormat);
                    } else {
                        var d = new Date(time);
                        var localTime = d.getTime();
                        var localOffset = d.getTimezoneOffset() * 60000;
                        var utc = localTime + localOffset;
                        var offset = 16;
                        var userTimezoneTime = utc + 3600000 * offset;
                        var nd = new Date(userTimezoneTime);
                        return parseTime(nd, cFormat);
                    }
                } else {
                    return "-";
                }
            };
        }
    },
    methods: {
        getTimezoneOffset() {
            let date = new Date();
            let timezoneOffset = 0;
            if (date.getTimezoneOffset() < 0) {
                timezoneOffset = (24 + date.getTimezoneOffset() / 60) * 60;
            } else {
                timezoneOffset = Math.abs(date.getTimezoneOffset());
            }
            return timezoneOffset;
        },
    }
}
