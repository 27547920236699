import axios from "axios";
import netConstants from "../net/netConstants";
import db from "./localstorage";

// 请求超时时间，30s
const requestTimeOut = 30 * 1000;
// const success = 200
// 更换令牌的时间区间
// const checkRegion = 5 * 60 * 1000
// 提示信息显示时长
// const messageDuration = 5 * 1000
// 请求中断
const CancelToken = axios.CancelToken;

// 系统全局请求对象
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://dev-api.gendome.net/', //这里写后端地址
  timeout: requestTimeOut,
  responseType: "json",
});
// console.log("process.env",process.env)

// request interceptor
service.interceptors.request.use(
  function (config) {
    // console.log("拦截器", config.url)
    if (config.url == netConstants.api_getToken) {
      config.headers["Authorization"] = "Basic ZGF0YXY6ZGF0YXYxMjM0NTY=";
    } else {
      if (config.url != netConstants.api_screen_getToken) {
        // 在发送请求之前做些什么
        if (db.get("ACCESS_TOKEN")) {
          config.headers["Authorization"] =
            db.get("TOKEN_TYPE") + " " + db.get("ACCESS_TOKEN");
        } else {
          config.headers["Authorization"] = "Basic ZGF0YXY6ZGF0YXYxMjM0NTY=";
        }
      }
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (config) => {
    // config.headers["Authorization"]= 'bearer '+db.get("ACCESS_TOKEN")

    return config;
  },
  (error) => {
    // if (error.response) {

    // }
    if (error.message.includes("timeout")) {
      alert("请求服务器超时");
    }
    return Promise.reject(error);
  }
);

const request = {
  post(url, params) {
    return service.post(url, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  put(url, params) {
    return service.put(url, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  get(url, params, abrot) {
    let _params;
    if (Object.is(params, undefined)) {
      _params = "";
    } else {
      _params = "?";
      for (const key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`;
        }
      }
    }
    return service.get(`${url}${_params}`, {
      cancelToken: new CancelToken(function executor(c) {
        abrot && abrot(c);
      }),
    });
  },
};

export default request;
