<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import netConstants from './net/netConstants'

export default {
  data() {
    return {
      timer: null,
    };
  },
  components: {},
  mounted() {
    this.timer = setInterval(() => {
      this.$EventBus.$emit("globalRefresh");
    }, 3600000);


  },
  methods: {

  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #020308;
  overflow: hidden;
}
</style>
