export default {
  api_getToken: "aiot-auth/oauth/token?grant_type=client_credentials", ///获取token
  api_screen_getToken: "aiot-accountcenter/screen/auth/token", ///获取token
  // api_deviceStatistics:"aiot-devicecenter/dashboard/info/deviceStatistics",///设备数据统计
  // api_deviceFaultStatistics:"aiot-devicecenter/dashboard/info/deviceFaultStatistics",///故障和告警统计

  api_deviceStatistics: "aiot-datacenter/data/deviceStatistics", //设备数据统计（含告警数据）
  api_chargeAnalysis: "aiot-datacenter/data/chargeAnalysis/charge", //新能源充电量
  api_countryChargePowerList: "aiot-datacenter/data/country/chargePowerList", //月度国家充电量榜单
  // api_chargeLast7dayCharge: "aiot-datacenter/data/charge/last7dayCharge", //最近7天充电量趋势
  api_chargeLast7dayCharge: "aiot-datacenter/data/charge/trend", //最近7天充电量趋势
  // api_chargeLast7dayAlarm:"aiot-datacenter/data/charge/last7dayAlarm",//最近7天告警趋势
  //以上接口更改为以下：
  api_periodLast7dayAlarm: "aiot-datacenter/data/alarm/period", //最近7天告警趋势（含告警数据）
  api_deviceRegionStatistics: "aiot-datacenter/data/deviceRegionStatistics", //设备区域分布统计
  api_appUserStatistics: "aiot-devicecenter/dashboard/info/appUserStatistics", //用户数据
  api_categoryStatistics: "aiot-datacenter/data/category/statistics", //产品销售品类统计

  // 这个接口可以用来自己发送实时的告警，
  // 故障的数据，http://dev-api.gendome.net/aiot-devicecenter/test/push
  api_testPush: "aiot-devicecenter/test/push",

  // 设备大屏接口
  api_equipmentInfo: "aiot-datacenter/data/singleDevice/dashboard/deviceInfo", //设备大屏设备信息
  api_cellVoltageTrack: "aiot-datacenter/data/singleDevice/16HostVoltage", //16个电芯电压近15分钟轨迹
  // api_warningTrend:"aiot-datacenter/data/singleDevice/last7dayAlarm",//单设备近7日告警趋势
  //以上接口更改为以下：
  api_warningTrend: "aiot-datacenter/data/singleDevice/alarm/period", //单设备近7日告警趋势（含告警数据）
  // api_chargeTrend: "aiot-datacenter/data/singleDevice/last7dayCharge", //单设备近7日新能源充电趋势
  api_realTimeData: "aiot-datacenter/data/singleDevice/realTime", //实时数据(soc/温度,功率等)
  api_faultWarning: "aiot-datacenter/data/device/recentAlarms", //近10条故障告警（含告警数据）
};
