import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
import "qweather-icons/font/qweather-icons.css";
// 引入全局css
import "./assets/scss/style.scss";
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import request from "./utils/request";
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$put = request.put;

Vue.prototype.$EventBus = new Vue();

//引入echart
//4.x 引用方式
import echarts from "echarts";
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

// 全局注册
Vue.component("icon", Icon);
Vue.use(dataV);
Vue.use(ElementUI);

import zh from "@/common/lang/zh.js";
import en from "@/common/lang/en.js";
import VueI18n from "vue-i18n";
import mixins from "@/mixins";
Vue.use(VueI18n);

Vue.mixin(mixins)

//组合语言包对象
const messages = {
  zh,
  en,
};

const i18n = new VueI18n({
  messages,
  locale: "zh",
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
